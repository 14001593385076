.formBuilder__budgetField {
    &-editor {
      display: flex;
      gap: 0.25rem;
      align-items: center;
      justify-content: center;
    }
    &-table {
      max-width: 56rem;
    }
    &-priceGroup {
      max-width:144px;
    }
    &-addLine{
      margin: 1rem auto 1.5rem 0;
      float: right;
      color: var(--secondary);
      cursor: pointer;
      &:hover{
        opacity: 0.87;
        text-decoration: underline;
      }
    }
    &-cell {
      text-align: right;
      &--label{
        text-align: left;
      }
      &--total{
        border-top: 3px solid black;
        font-size: 1.1em;
      }
    }
  }